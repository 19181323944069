.contact-section{
    min-height: 100vh;
    height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
        gap: 5rem;
    }

.contact-heading {
    position: relative;
    inset: 0;
    top: 10rem;
}

.infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    width: fit-content;
    max-width: 90vw;
    position: relative;
    top: 15rem;
}

.contatti {
    text-align: center;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 65px;
    line-height: 100px;
    font-weight: 600;
    position: relative;
    width: fit-content;
}

.contatti-span {
    color: #000000;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 65px;
    line-height: 100px;
    font-weight: 600;
}

.contatti-span2 {
    color: #315784;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 65px;
    line-height: 100px;
    font-weight: 600;
}

.luigi-collivasone-gmail-com {
    color: #000000;
    text-align: center;
    font-family: "Poppins-Italic", sans-serif;
    font-size: clamp(22px, 3vw, 25px);
    font-weight: 400;
    font-style: italic;
    position: relative;
}

._39-3920234632 {
    color: #000000;
    text-align: center;
    font-family: "Poppins-Italic", sans-serif;
    font-size: clamp(22px, 3vw, 25px);
    font-weight: 400;
    font-style: italic;
    position: relative;
}

.infos a {
    text-decoration: none;
    color:#315784;
&&:hover{
    font-size: clamp(22px, 3vw, 25px);
    text-decoration: underline;
    color: #354758;
}

}


.studio-privato-in-via-torretta-7-pavia {
    color: #000000;
    text-align: center;
    font-family: "Poppins-Italic", sans-serif;
    font-size: clamp(22px, 3vw, 25px);
    font-weight: 400;
    font-style: italic;
    position: relative;
}

.review-google {
    color: #315784;
    text-align: center;
    font-family: "Poppins-SemiBoldItalic", sans-serif;
    font-size: 32px;
    font-weight: 600;
    font-style: italic;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1095px;
    width: fit-content;
        max-width: 90vw;
}

.review-google a {
    text-decoration: none;
    color: #315784;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.stars {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 7rem;
    align-items: center;
    justify-content: center;
    position: relative;
}

@media (min-width: 1400px) and (max-height: 768px) {
 
.contact-heading{
    top: 2.5;
}

.infos{
    top: 5rem;
}

}


@media screen and (max-width: 690px){

    .contact-section{
        height: 120vh;
    }

    .contatti-span {
        font-size: clamp(45px, 5vw, 70px);
    }

    .infos a {
        font-size: clamp(17px, 5vw, 70px);
        padding-bottom: 5%;
    }
}