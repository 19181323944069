.about-me {
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-heading {
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    inset: 0;
}

.su-di-me-container {
    text-align: center;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 65px;
    line-height: 100px;
    font-weight: 600;
    position: relative;
    top: 8rem;
}

.su-di-me-span {
    color: #000000;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 65px;
    line-height: 100px;
    font-weight: 600;
}

.su-di-me-span2 {
    color: #315784;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 65px;
    line-height: 100px;
    font-weight: 600;
}

.description-aboutpage {
    color: #000000;
    text-align: center;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 20px;
    position: relative;
    width: 50%;
    top: 10rem;
    height: fit-content;
}

.image-about-centered{
    position: relative;
    top: 3rem;
    width: 80%;
}

@media only screen and (max-width: 1000px) {


    .su-di-me-container {
            font-size: 30px;
            line-height: 50px;
            top: 2rem;
        }

    .image-about-centered {
        top: 4rem;
        max-width: 100%;
            min-width: 95%;
    }

    .description-aboutpage {
        width: 80%;
        padding: 20px 0;
    }

        .description-aboutpage {
            width: 90%;
            font-size: clamp(18px, 3vw, 30px);
            top: 4rem;
        }

}

