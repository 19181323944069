.footer {
background-color: #000000;
height: 50vh;
width: 100vw;
box-shadow: 0px -70px 70px -61px rgba(0, 0, 0, 1);
display: flex;
flex-direction: column;
justify-content: center;
}

.footer a {
text-decoration: none;
color: white;
&&:hover{
color: #5E80A5;
font-size: larger;
cursor: pointer;
}

}

.upvisionMedia-footer:hover{
color: orange;
}

.group-frame-footer{
display: flex;
flex-direction: row;
gap: 2%;
justify-content: center;
align-items: center;
position: relative;
}

.footer-1,
.footer-2,
.footer-3
{
width: 100%;
}



.footer-logo{
width: 60%;
}

.upvisionmedia-mark{
color: white;
position: relative;
top: 4rem;
}

.upvisionMedia-footer{
color:#E4C49D;
font-size: 30px;
font-style: bold;

}

.home-button-footer,
.social-button-footer
{
color: white;
font-size: 40px;
}

.footer-2 h4,
.footer-3 h4
{
color: white;
font-size: 20px;
}

.footer-2,
.footer-3
{
display: flex;
flex-direction: column;
gap: 1rem;
align-items: center;
}

.button-contact-footer{
background: #315784;
border-radius: 5px;
padding: 5px 26px 5px 26px;
display: flex;
flex-direction: row;
cursor: pointer;
gap: 10px;
color: white;
align-items: center;
justify-content: center;
width: 40%;
height: 3rem;
position: relative;
top: 1.5rem;
box-shadow: 0px 1px 50px 0px rgba(228, 196, 157, 0.5);
}

.button-contact-footer:hover {
    transition: 1s ease-in-out;
    color: black;
    background: #E4C49D;
    font-size: 30px;
    width: 40%;
    height: 4.5rem;
}

.button-contact-footer:hover svg {
    stroke-dashoffset: -480;
}

@media screen and (max-width: 1100px) {
    .group-frame-footer {
        flex-direction: row;
        /* Adjusted back to row for larger screens */
    }

   .footer {
       width: 100vw;
   }
   .upvisionmedia-mark{
    top: 7rem;
   }
}

@media screen and (max-width: 600px) {
    .group-frame-footer {
        flex-direction: column;
        gap: 2.5rem;
    }

   .footer {
       width: 100vw;
       min-height: 100vh;
       height: 130vh;
       padding-bottom: 5%;
   }
}

@media screen and (max-width: 300px) {
    .group-frame-footer {
        flex-direction: column;
        gap: 2rem;
    }
}