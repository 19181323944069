

.container-services {
width: 90%;
min-height: 100vh;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.services-heading,
.services-heading * {
box-sizing: border-box;
}

.services-heading {
position: relative;
inset: 0;
width: fit-content;
}

.servizi-span {
color: #000000;
font-family: "Poppins-SemiBold", sans-serif;
font-size: 65px;
line-height: 100px;
font-weight: 600;
}

.servizi-span2 {
color: #315784;
font-family: "Poppins-SemiBold", sans-serif;
font-size: 65px;
line-height: 100px;
font-weight: 600;
}

.ecco-in-che-modo-posso-aiutar-ti {
text-align: center;
font-family: "Poppins-Regular", sans-serif;
font-size: 20px;
font-weight: 400;
position: absolute;
}

.ecco-in-che-modo-posso-aiutar-ti-span {
color: #000000;
font-family: "Poppins-Regular", sans-serif;
font-size: 20px;
font-weight: 400;
}

.ecco-in-che-modo-posso-aiutar-ti-span2 {
color: #315784;
font-family: "Poppins-Regular", sans-serif;
font-size: 20px;
font-weight: 400;
}
.row {
display: flex;
justify-content: space-around;
flex-flow: wrap;
position: relative;
}

.card {
width: 15%;
background: #ffffff;
border-radius: 20px;
padding: 10px;
position: relative;
box-shadow: 0px 5px 20px 0px rgba(49, 87, 132, 1);
transition: 0.3s;
}

.middle {
    top: 5rem;
}

.icon {
width: 100px;
}

.card-body {
padding: 30px 20px;
text-align: center;
font-size: 18px;
}


.card-body .btn:hover {
transform: scale(1.1);
}

.card:hover {
transform: scale(1.1);
box-shadow: 0 0 40px -10px #E4C49D;
background-color: #5E80A5;
color: white;
}

/* Responsive media queries */
@media screen and (max-width: 1350px) {

    .row {
        gap: 5%;
        top: 8rem;
    }

    .services-heading{
        top: 7rem;
    }

    .card {
        min-width: 40%;
        margin: 2%;
    }

    .middle {
        top: 0;
    }
}


@media screen and (max-width: 1000px) {

    .row {
        gap: 5%;
        padding: 5% 0;
        top: 8rem;
    }

    .services-heading { 
        top: 8rem;
    }

.card {
min-width: 35%;
margin: 2%;
}

.middle {
    top: 0;
}
}

@media screen and (max-width: 620px) {
.container {
width: 100%;
}

.heading {
padding: 20px;
font-size: 30px;
}
.card {
    min-width: fit-content;
}
}