.home-container {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    min-height: 100vh;
    padding: 0 5% 0 10%;
    position: relative;
    top: 6rem;

}

a {
    text-decoration: none;
}
/* RIGHT-SIDE */

.right-side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-picture{
    max-width: 90%;
        height: auto;
        display: block;
        left: 0;
        padding:0;
}


/* LEFT-SIDE */

.header-details {
    box-sizing: border-box;
        width: 100%;
        position: relative;
}

.left-side{
    position: relative;
    top: -3rem;
}


.ciao-sono-luigi-carlo-collivasone {
    color: #000000;
    text-align: left;
    position: relative;
    left: 0px;
    width: fit-content;
}

.ciao-sono-luigi-carlo-collivasone-span {
    color: #000000;
    font-family: "Poppins-SemiBold", sans-serif;
                font-size: clamp(12px, 3vw, 20px);
    font-weight: 600;
}

.ciao-sono-luigi-carlo-collivasone-span2 {
    color: #000000;
    font-family: "Poppins-SemiBold", sans-serif;
            font-size: clamp(10px, 3vw, 25px);
    font-weight: 600;
}

.proffession {
    position: relative;
    width: fit-content;
}

.psicologo-e {
    color: #000000;
    text-align: left;
    font-family: "Poppins-SemiBold", sans-serif;
        font-size: clamp(50px, 4vw, 90px);
    font-weight: 600;
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
}

.psicoterapeuta {
    text-align: left;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: clamp(45px, 3vw, 90px);
    font-weight: 600;
    position: relative;
    width: fit-content;
}

.psicoterapeuta-span {
    color: #000000;
    font-family: "Poppins-SemiBold", sans-serif;
    font-weight: 600;
}

.psicoterapeuta-span2 {
    color: #315784;
    font-family: "Poppins-SemiBold", sans-serif;
        font-size: clamp(45px, 4vw, 100px);
    font-weight: 600;
}

.description {
    position: relative;
    inset: 0;
color: #000000;
    text-align: justify;
    font-family: "Poppins-Regular", sans-serif;
    font-size: clamp(20px, 4vw, 22px);
    font-weight: 400;
    left: 0px;
    top: 2.5rem;
    width: 70%;
}


.cv-btn,
.cv-btn * {
    box-sizing: border-box;
}


.rectangle-3800 {
    background: #315784;
    border-radius: 20px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    position: relative;
    top: 6rem;
    box-shadow: 0px 4px 4px 0px rgba(49, 87, 132, 0.5);
}

.contattami {
    color: #fffefd;
    text-align: left;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    width: fit-content;
    height: 27px;
}

.social-media {
    width: 25%;
    position: relative;
    top: 4.5rem;
    display: flex;
    left: 2rem;
}

.social-media img {
    width: 50%;
}

.social-button {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

@media (max-width: 1250px) {

    .header-details{
    font-size: smaller;
    }
        .psicoterapeuta-span{
            font-size: 60px;
        }
}

@media (max-width: 950px) {
.home-container{
    display: grid;
        grid-template-rows: repeat(2, 1fr);
        top: 2rem;

}

.proffession {
    top: 1rem;
    max-width: 95%;
}
    .psicoterapeuta-span {
        font-size: larger;
    }


.description {
    width: 95%;
    font-size: 18px;
}


.title {
    width: fit-content;
}

.header-details{
    position: relative;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;

}

.ciao-sono-luigi-carlo-collivasone{
    width: fit-content;

}

.left-side {
    grid-area: 1;
    width: 90vw;
    justify-content: center;
    align-items: center;
}

.right-side {
    grid-area: 2;
    width: 90vw;
}
}

@media (max-width: 480px) {
    .home-container {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        top: 6rem;
        padding:5% ;

    }

    .ciao-sono-luigi-carlo-collivasone {
        top: 2rem;
        padding: 10px 0;
    }

    .proffession {
        top: 2rem;
        max-width: 95%;
    }

    .psicologo-e,
    .psicoterapeuta-span {
        font-size: 30px;
    }
.rectangle-3800 {
    width: 70%;
    margin: 0 auto;
}

.description {
    font-size: 15px;
    padding-top: 20px;
}

.profile-picture{
    position: relative;
    top: 2rem;
}
}