.specializations-container {
background-image: url("../assets/back.jpg");
background-repeat: no-repeat;
background-size: cover;
display: flex;
flex-direction: column;
min-height: 130vh;
height: fit-content;
padding-bottom: 10%;
align-items: center;
box-sizing: border-box;
-webkit-box-shadow: 0px 0px 28px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 28px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 28px -1px rgba(0, 0, 0, 0.75);
}


.title-section {
width: fit-content;
height: fit-content;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 50%;
top: 5.5rem;
position: relative;
}

.description-specializations {
color: #000000;
text-align: center;
font-family: "Poppins-Regular", sans-serif;
font-size: 20px;
font-weight: 400;
position: relative;
width: 100%;
top: 4rem;
}

.specializzazioni {
text-align: center;
font-family: "Poppins-SemiBold", sans-serif;
font-size: clamp(30px, 4vw, 60px);
font-weight: 600;
position: relative;
height: 83px;
}

.specializzazioni-span {
color: #000000;
font-family: "Poppins-SemiBold", sans-serif;
font-size: clamp(45px, 4vw, 60px);
line-height: 100px;
font-weight: 600;
}

.specializzazioni-span2 {
color: #315784;
font-family: "Poppins-SemiBold", sans-serif;
font-size: clamp(80px, 5vw, 100px);
font-weight: 500;
}

.container-cards{
background-color: rgba(0, 0, 0, 0.527);
border-radius: 30px;
height: fit-content;
width: 90vw;
position: relative;
display: flex;
top: 10rem;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
gap: 3%;
padding: 1% 0;
-webkit-box-shadow: 0px 0px 28px -1px rgba(228, 196, 157, 1);
-moz-box-shadow: 0px 0px 28px -1px rgba(228, 196, 157, 1);
box-shadow: 0px 0px 28px -1px rgba(228, 196, 157, 1);
}

.card-specialization{
width: 300px; 
height: 400px;
position: relative;
color: rgb(250, 250, 250);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 4rem;
padding: 10px;
border-radius: 20px;
border: 2px solid rgb(250, 226, 175);
}

.card-specialization:hover{
background-color: #5E80A5;
.title-card {
color: #E4C49D;
}

.description-card-spe{
    font-size: 20px;
}

}


@media screen and (max-width: 1500px) {

    .specializations-container {
        padding-bottom: 20%;
    }
}

@media screen and (max-width: 950px) {

    .specializations-container{
        padding-bottom: 25%;
    }
}

@media screen and (max-width: 768px) {

    .specializations-container {
        padding-bottom: 35%;
    }
}

@media screen and (max-width: 480px) {

    .specializations-container {
        padding-bottom: 45%;
    }

    .specializzazioni-span{
        font-size: smaller;
    }
}