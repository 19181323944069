.App {
  text-align: center;
}


::-webkit-scrollbar{
  width: 20px;
}

::-webkit-scrollbar-track{
  background: white;
}

::-webkit-scrollbar-thumb{
  background: #315784;
}

::-webkit-scrollbar-thumb:hover{
  background: #E4C49D;
}