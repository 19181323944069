* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.navbar {
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 10;
    -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        text-shadow: 1px -1px 4px rgba(255, 255, 255, 1);
}


.navbar-responsive {
    color: #fff;
    position: fixed;
    height: 100%;
    width: 100%;
    transform: translate3d(-100%, 0, 0);
    transition: .3s all ease-in-out;
}

.navbar-responsive:after {
    content: "";
    z-index: -1;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.925);
}

.navbar-responsive .navbar-responsive__nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
}

.navbar-responsive .navbar-responsive__nav .navbar-responsive__link {
    text-align: center;
    letter-spacing: 2px;
}

.navbar-responsive .navbar-responsive__nav .navbar-responsive__link a {
    display: block;
    padding: 15px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
}

.navbar-responsive .navbar-responsive__nav .navbar-responsive__link a:after {
    top: 50%;
    bottom: 0;
    left: 0;
    
}

.navbar .navbar__wrapper {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.navbar .navbar__wrapper .navbar__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
}

  .navbar__brand {
      min-width: 15%;
  }
.navbar .navbar__wrapper .navbar__menu .navbar__nav {
    display: flex;
    list-style: none;
}

.navbar .navbar__wrapper .navbar__link a,
.navbar .navbar__wrapper .navbar__brand {
    display: block;
    position: relative;
    padding: 12px;
    color:#315784;
    letter-spacing: 0.1em;
    text-decoration: none;
}


.contact {
background: #315784;
    border-radius: 50px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.25);
    border-width: 1px;
    padding: 18px 60px 18px 60px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    box-shadow: 0px 20px 25px 0px rgba(228, 196, 157, 0.5);
}



.contact1 {
    color: #ffffff;
    text-shadow: none;
        text-align: left;
        font-family: "DmSans-Medium", sans-serif;
        font-size: 16px;
        font-weight: 500;
        position: relative;
}

.navbar .navbar__wrapper{
    font-weight: 700;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.after-transform a.active:after,
.after-transform a:hover:after {
    transform: scaleX(1) translateZ(0);
}

/* Icon 3 */
#nav-icon3 {
    width: 30px;
    height: calc(100%/2);
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon3 span {
    display: none;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #777;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
    top: 0px;
    background-color: black;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
    top: 9px;
    background-color: black;
}

#nav-icon3 span:nth-child(4) {
    top: 18px;
    background-color: black;
}

#nav-icon3.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}

#nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.navbar-grhy a{
    color: #E4C49D;
    font-size: 24px;
    border: 1px solid #E4C49D;
    border-radius: 20px;
    padding: 5%;
    background-color: #5E80A5;
    position: relative;
    top: 5rem;
    -webkit-box-shadow: 0px 0px 66px -6px rgba(94, 128, 165, 1);
        -moz-box-shadow: 0px 0px 66px -6px rgba(94, 128, 165, 1);
        box-shadow: 0px 0px 66px -6px rgba(94, 128, 165, 1);
}

#nav-icon3.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
}


@media (max-width: 950px) {
    .navbar__nav {
        display: none !important;
    }

    .navbar-responsive{
        z-index: 9999;
    }

    #nav-icon3 span {
        display: block !important;
    }
        .mobile-visible {
            display: none;
        }

        .navbar__brand {
            min-width: 40%; 
        }
}

@media (min-width: 950px) {
    .navbar-responsive {
        transform: translate3d(-100%, 0, 0) !important;

    }
}